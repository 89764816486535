import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

import imgPhone from "../../assets/image/png/business-detail-and-list.png";

const SectionStyled = styled(Section)``;

const WidgetContainer = styled(Box)`
  counter-reset: widget-counter;
`;

const WidgetStyled = styled(Box)`
  padding-left: 70px;
  position: relative;
  max-width: 411px;
  &:before {
    counter-increment: widget-counter;
    content: counter(widget-counter);
    position: absolute;
    left: 0;
    min-width: 49px;
    width: 49px;
    height: 49px;
    background-color: ${({ theme }) => theme.colors.secondary};
    border-radius: 500px;
    display: inline-flex;
    justify-content: center;
    align-items: center;
    color: ${({ theme }) => theme.colors.light};
    font-size: 24px;
    font-weight: 700;
    letter-spacing: -0.75px;
  }
`;

const Widget = ({ num = 1, title = "", children = "", ...rest }) => {
  return (
    <WidgetStyled {...rest}>
      <Title variant="card" mb={2}>
        {title}
      </Title>
      <Text variant="small">{children}</Text>
    </WidgetStyled>
  );
};

const Content0 = () => {
  return (
    <>
      {/* <!-- Content1 section --> */}
      <SectionStyled>
        <Container>
          <Row className="align-items-center">
            <Col lg="6" className="mb-4 mb-lg-0 pl-lg-5 order-lg-1">
              <div
                data-aos="fade-right"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <img src={imgPhone} alt="" className="img-fluid" />
              </div>
            </Col>
            <Col lg="6" md={9} className="order-lg-2">
              <div>
                  <Title>
		    Attract customers
                </Title>
                <Text>
                    Its all about customer acquisition and retention
                </Text>
                <WidgetContainer mt={5}>
                    <Widget title="Create an online presence with a free profile page" mb={4}>
			Listing your business details, contact information, images etc are all Free on Kesto. Weather you have a website or not, your Kesto page is your digital business card easily shareable with everyone, anywhere.
                    </Widget>
                    <Widget title="Get the word out with Posts" mb={4}>
			Engage Kesto users with free posts that show up inside your profile, visible to all users visiting your profile page
                    </Widget>
		    
                    <Widget title="Discounts and Deals" mb={4}>
			Your posts for special deals are added in Kesto's "Deal" channel so you can get high visibility with users.
                    </Widget>

                    <Widget title="Grow your business with Ads" mb={4}>
			Our mobile advertising functionalitiy enables you to reach our users through different types of banner ads, full-page images, and video ads to find new customers and meet your marketing goals.
                    </Widget>
                    
                </WidgetContainer>
              </div>
            </Col>
          </Row>
        </Container>
      </SectionStyled>
    </>
  );
};

export default Content0;
