import React from "react";
import styled from "styled-components";
import { Container, Row, Col } from "react-bootstrap";

import { Title, Section, Box, Text } from "../../components/Core";

// import imgContent1 from "../../assets/image/jpeg/easy-image-2-1.jpg";
// import imgContent2 from "../../assets/image/jpeg/easy-image-2-2.jpg";

const ContentImg = styled(Box)`
  box-shadow: ${({ theme }) => `0 42px 54px ${theme.colors.shadow}`};
  border-radius: 10px;
  overflow: hidden;
  max-height: 515px;
`;

const Content = () => (
  <>
    <Section>
      <Container>
        <Row className="justify-content-center pb-4">
          <Col lg="12" className="pl-lg-5">
              <Text>
		  The challenge of most small businesses is connecting with the customers, and to do that customers have to first discover you. Kesto provides a unique opportunity, a first of its kind in Ethiopians & Eritreans community, to connect your business or service to customers. 

            </Text>
          </Col>
        </Row>

        <Row className="justify-content-center pb-4">
          <Col lg="12" className="pl-lg-5">
              <Text>
		  Our team is always available to disuss on how best to utilize Kesto to increase your customers. Feel free to send us a note at the using the contact link provided.
            </Text>
          </Col>
        </Row>
	  
      </Container>
    </Section>
  </>
);

export default Content;
