import React from "react";

import PageWrapper from "../components/PageWrapper";
import Hero from "../sections/common/Hero";
import Content from "../sections/business/Content";

// import Feature from "../sections/business/Feature";
import Content0 from "../sections/business/Content0";
import Content1 from "../sections/business/Content1";
// import Content3 from "../sections/business/Content3";
// import Content4 from "../sections/business/Content4";
//import BizFaq from "../sections/business/Faq";

const AboutBiz = () => {
  return (
    <>
      <PageWrapper>
        <Hero title="Business">
        </Hero>
          <Content />
          <Content0 />
          <Content1 />
      </PageWrapper>
    </>
  );
};
export default AboutBiz;
